<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <Toast />
                <div class="p-col-12 p-md-4">
                    <h5>{{ fixture.Name }}</h5>
                    <h6>{{ formatCSDateAndTime(fixture.Date) }}</h6>
                </div>
                <div class="p-col-12 p-md-4">
                    <div v-if="fixturesActiveBool">
                        <div v-if="eventStarted">
                            <div class="p-col-12 p-grid" v-if="hasResults">
                                <p class="p-mt-2">
                                    <b>{{ fixture.Results.HomeScore }} - {{ fixture.Results.AwayScore }}</b>
                                </p>
                                <p class="p-mt-2" v-for="period in fixture.Results.Periods" :key="'period' + period.Number">({{ period.HomeScore }} - {{ period.AwayScore }})</p>
                                <Button @click="changeResults()" :label="$t('buttons.changeResults')" class="p-button-text p-button-warning p-mr-2 p-mb-2" />
                            </div>
                            <div v-else class="p-col-12 p-grid">
                                <label class="p-col-6 p-mb-2 p-mb-md-0">{{ $t('results.homeScore') }} *</label>
                                <div class="p-col-6">
                                    <InputText style="width: 100%" type="text" v-model="fixture.Results.HomeScore" />
                                </div>
                                <label class="p-col-6 p-mb-2 p-mb-md-0">{{ $t('results.awayScore') }} *</label>
                                <div class="p-col-6">
                                    <InputText style="width: 100%" type="text" v-model="fixture.Results.AwayScore" />
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ $t('results.eventNotStarted') }}
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-text-right">
                    <Button v-show="isProcessBool" @click="reactivateEvent()" :label="$t('buttons.reactivateEvent')" class="p-button-raised p-button-warning p-mr-2 p-mb-2" />
                    <Button @click="voidEvent()" :label="$t('buttons.voidEvent')" class="p-button-raised p-button-warning p-mr-2 p-mb-2" />
                    <Button @click="confirmResults()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button v-show="!isProcessBool" :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
                <Fieldset v-show="isProcessBool" :legend="$t('results.changeDate')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-6 p-md-2">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showIcon="true" :showTime="true" hourFormat="24" :showButtonBar="true" v-model="fixture.Date"></Calendar>
                        </div>
                        <div class="p-col-6 p-md-4">
                            <Button @click="saveDate()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                        </div>
                        <transition-group name="p-message" tag="div" class="p-col-12 p-mb-2">
                            <Message :key="errorKeyCountDate" :severity="dateMessageSeverity" v-show="showDateMessage">{{ dateMessage }}</Message>
                        </transition-group>
                    </div>
                </Fieldset>
                <Fieldset v-show="isProcessBool" :legend="$t('results.changeTournament')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-6 p-md-4">
                            <Dropdown style="width: 100%" v-model="tournamentModel" :options="tournaments" :placeholder="$t('dropdown.placeholder.tournament')" optionLabel="name" :filter="!isMobile()" />
                        </div>
                        <div class="p-col-6 p-md-4">
                            <Button @click="saveTournament()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                        </div>
                        <transition-group name="p-message" tag="div" class="p-col-12 p-mb-2">
                            <Message :key="errorKeyCountTournament" :severity="tournamentMessageSeverity" v-show="showTournamentMessage">{{ tournamentMessage }}</Message>
                        </transition-group>
                    </div>
                </Fieldset>
                <Fieldset :legend="$t('results.eventMarkets')" :toggleable="true" :collapsed="false" class="p-col-12 p-mb-2">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-6 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="marketsDropdown" style="width: 100%" v-model="selectedMarket" :options="markets" optionLabel="name" :filter="!isMobile()" />
                                <label for="marketsDropdown">{{ $t('dropdown.placeholder.market') }}</label>
                            </span>
                        </div>
                        <div class="p-col-6 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="statusDropdown" style="width: 100%" v-model="selectedStatus" :options="marketsStatus" optionLabel="name" :filter="!isMobile()" />
                                <label for="statusDropdown">{{ $t('dropdown.placeholder.status') }}</label>
                            </span>
                        </div>
                        <div class="card p-col-12 p-grid p-mb-3" v-for="(market, index) in filteredData" :key="'market' + index" v-show="!showFixtureLoading">
                            <div class="p-col-12 p-md-6">
                                <span
                                    ><b>{{ market.Name }}</b> {{ market.Specifier }}</span
                                >
                            </div>
                            <div class="p-col-12 p-md-6 p-text-right">
                                <Button :label="market.Status" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + getStatusColor(market.Status)" />
                            </div>
                            <div v-for="(outcome, index) in market.Outcomes" :key="'outcome' + market.Id + '_' + outcome.Id + '_' + index" class="p-col-12 p-grid p-pb-0 p-pt-0">
                                <div class="p-col-2 p-md-2">{{ outcome.Name }}</div>
                                <div class="p-col-2 p-md-2">{{ outcome.Odds }}</div>
                                <div class="p-col-6 p-md-4 p-text-right">
                                    <Button
                                        v-if="market.Status === 'SETTLED' || outcome.Result !== null"
                                        :icon="'pi pi-' + getOutcomeStatusIcon(outcome.Result)"
                                        :class="'p-button-rounded p-button-text p-mb-2 p-button-' + getOutcomeStatusColor(outcome.Result)"
                                    />
                                    <Button @click="voidOutcome(outcome)" :label="$t('buttons.void')" class="p-button-text p-button-warning p-mb-2" />
                                </div>
                                <div class="p-col-12 p-md-4 p-text-right">
                                    <div v-if="isProcessBool || isPendingResultsBool || market.Status == 'ACTIVE'" class="p-grid p-col-12">
                                        <div class="p-col-4">
                                            <div class="p-field-radiobutton">
                                                <RadioButton id="option2" name="option" :value="true" v-model="outcome.Result" />
                                                <label for="option2">{{ $t('results.resultTrue') }}</label>
                                            </div>
                                        </div>
                                        <div class="p-col-4">
                                            <div class="p-field-radiobutton">
                                                <RadioButton id="option3" name="option" :value="false" v-model="outcome.Result" />
                                                <label for="option3">{{ $t('results.resultFalse') }}</label>
                                            </div>
                                        </div>
                                        <div class="p-col-4">
                                            <Button v-show="outcome.Result != null" @click="clearSelection(outcome)" :label="$t('buttons.removeResult')" class="p-button-text p-button-info p-mr-2 p-mb-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
                <Dialog :header="$t('results.dialog.addResultsTitle')" v-model:visible="showModal" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ $t('results.dialog.addResultsText', { count: changedResults }) }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="closeModals()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="addResults()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
                <Dialog :header="$t('results.dialog.validatedTicketsTitle')" v-model:visible="showModalTickets" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ $t('results.dialog.validatedTicketsText', { count: validatedTickets }) }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="closeModals()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.showTickets')" @click="viewTickets()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
                <Dialog :header="$t('results.dialog.voidConfirmation')" v-model:visible="showVoidModal" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ $t('results.dialog.voidEvent') }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="removeVoid()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="addResults()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
                <Dialog :header="$t('results.dialog.reactivateConfirmation')" v-model:visible="showActiveModal" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ $t('results.dialog.reactivateEvent') }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="removeActive()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="addResults()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { SportsApi } from '../../service/SportsApi';
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { BoSportsApi } from '../../service/BoSportsApi';
export default {
    name: 'resultDetails',
    data() {
        return {
            fixture: {
                Markets: [],
                Results: { HomeScore: '', AwayScore: '', Periods: [] },
                Name: '',
                Date: '',
            },
            SportsApi: new SportsApi(),
            BoSportsApi: new BoSportsApi(),
            SportsSettingsApi: new SportsSettingsApi(),
            markets: [],
            selectedStatus: { name: 'All', value: '0' },
            selectedMarket: { name: 'All', id: '0' },
            marketsStatus: [
                {
                    name: 'All',
                    value: '0',
                },
                {
                    name: 'SETTLED',
                    value: '1',
                },
                {
                    name: 'INACTIVE',
                    value: '2',
                },
                {
                    name: 'SUSPENDED',
                    value: '3',
                },
                {
                    name: 'ACTIVE',
                    value: '4',
                },
            ],
            initialFixture: {},
            tournaments: [],
            tournamentModel: {},
            errorKeyCountDate: 0,
            dateMessageSeverity: '',
            showDateMessage: false,
            dateMessage: '',
            errorKeyCountTournament: 0,
            tournamentMessageSeverity: '',
            showTournamentMessage: false,
            tournamentMessage: '',
            showModal: false,
            changedResults: 0,
            changedOutcomes: [],
            validatedTicketsSerials: '',
            showModalTickets: false,
            isVoided: false,
            showVoidModal: false,
            showActiveModal: false,
            isActive: false,
            resultsChanged: false,
        };
    },
    computed: {
        filteredData() {
            let markets = [];
            if (this.selectedMarket.id === '0' && this.selectedStatus.value === '0') {
                markets = this.fixture.Markets;
            }

            markets = this.fixture.Markets.filter((item) => {
                return (this.selectedMarket.id === '0' || item._id === this.selectedMarket.id) && (this.selectedStatus.value === '0' || item.Status === this.selectedStatus.name);
            });
            return markets;
        },
        validatedTickets() {
            if (this.validatedTicketsSerials == '' || this.validatedTicketsSerials == 'error' || this.validatedTicketsSerials == ' ') {
                return 0;
            } else {
                return this.validatedTicketsSerials.split(',').length - 1;
            }
        },
        hasResults() {
            return this.fixture.Results != null && this.fixture.Results.DateAdd != null;
        },
        fixturesActiveBool() {
            return String(this.fixturesActive).toLowerCase() === 'true';
        },
        isProcessBool() {
            return String(this.isProcess).toLowerCase() === 'true';
        },
        isResultsBool() {
            return String(this.isResults).toLowerCase() === 'true';
        },
        isPendingResultsBool() {
            return String(this.isPendingResults).toLowerCase() === 'true';
        },
    },
    created() {
        if (this.fixturesActiveBool) {
            this.SportsApi.getFixtureDetails(this.fixtureId, false)
                .then((response) => {
                    this.fixture = response.data;
                    this.initialFixture = JSON.parse(JSON.stringify(response.data));
                    if (this.fixture.Results == null) {
                        this.fixture.Results = { HomeScore: '', AwayScore: '', Periods: [] };
                    }
                    this.fixture.Date = this.formatCSDateAndTime(response.data.Date);
                    this.showFixtureLoading = false;
                    var marketsArray = [];
                    for (var i = 0; i < response.data.Markets.length; i++) {
                        var marketObj = {
                            name: response.data.Markets[i].Name,
                            id: response.data.Markets[i]._id,
                        };
                        var marketPosition = marketsArray
                            .map(function (m) {
                                return m.id;
                            })
                            .indexOf(response.data.Markets[i]._id);
                        if (marketPosition < 0) {
                            marketsArray.push(marketObj);
                        }
                    }
                    this.markets = marketsArray.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.markets.unshift(this.selectedMarket);
                    this.getTournaments();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
        } else {
            this.SportsApi.getOutrightDetails(this.fixtureId)
                .then((response) => {
                    this.fixture = response.data;
                    this.initialFixture = JSON.parse(JSON.stringify(response.data));
                    var marketsArray = [];
                    for (var i = 0; i < response.data.Markets.length; i++) {
                        var marketObj = {
                            name: response.data.Markets[i].Name,
                            id: response.data.Markets[i]._id,
                        };
                        var marketPosition = marketsArray
                            .map(function (m) {
                                return m.id;
                            })
                            .indexOf(response.data.Markets[i]._id);
                        if (marketPosition < 0) {
                            marketsArray.push(marketObj);
                        }
                    }
                    this.markets = marketsArray.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.showFixtureLoading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
        }
    },
    watch: {
        selectedStatus() {
            if (this.selectedStatus === '') {
                this.selectedStatus = { name: 'All', value: '0' };
            }
        },
        selectedMarket() {
            if (this.selectedMarket === '') {
                this.selectedMarket = { name: 'All', id: '0' };
            }
        },
    },
    props: {
        backRoute: {
            type: String,
        },
        fixturesActive: {
            type: String,
            default: 'false',
        },
        fixtureId: {
            type: String,
        },
        isProcess: {
            type: String,
            default: 'false',
        },
        isResults: {
            type: String,
            default: 'false',
        },
        isPendingResults: {
            type: String,
            default: 'false',
        },
    },
    methods: {
        getTournaments() {
            this.SportsSettingsApi.getAllTournaments(this.fixture.SportId, this.fixture.CategoryId).then((response) => {
                this.tournaments = response.data;
                this.tournamentModel = this.tournaments.find((s) => s.id == this.fixture.TournamentId);
            });
        },
        getStatusColor(status) {
            switch (status) {
                case 'SETTLED': {
                    return 'success';
                }
                case 'INACTIVE': {
                    return 'danger';
                }
                case 'SUSPENDED': {
                    return 'plain';
                }
                case 'ACTIVE': {
                    return 'info';
                }
            }
        },
        getOutcomeStatusColor(outcomeStatus) {
            if (outcomeStatus != null) {
                return outcomeStatus ? 'success' : 'danger';
            } else {
                return '';
            }
        },
        getOutcomeStatusIcon(outcomeStatus) {
            if (outcomeStatus != null) {
                return outcomeStatus ? 'check' : 'times';
            } else {
                return '';
            }
        },
        eventStarted() {
            if (this.fixture != null) {
                var fixtureDate = new Date(this.fixture.Date);
                var crtDate = new Date();
                if (fixtureDate < crtDate) {
                    return true;
                }
            }
            return false;
        },
        voidOutcome(outcome) {
            outcome.Odds = 1;
            outcome.Result = true;
        },
        clearSelection(outcome) {
            outcome.Result = null;
        },
        changeResults() {
            this.fixture.Results = { HomeScore: '', AwayScore: '', Periods: [] };
            this.resultsChanged = true;
        },
        saveDate() {
            this.showDateMessage = false;
            this.BoSportsApi.changeFixtureDate(this.fixture.FixtureId, this.fixture.Date)
                .then(() => {
                    this.dateMessage = this.$t('results.alerts.dateChanged');
                    this.dateMessageSeverity = 'success';
                    this.showDateMessage = true;
                    this.errorKeyCountDate++;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.dateMessage = this.$t('results.alerts.dateChangeError');
                    this.dateMessageSeverity = 'danger';
                    this.showDateMessage = true;
                    this.errorKeyCountDate++;
                });
        },
        saveTournament() {
            this.showTournamentMessage = false;
            this.SportsSettingsApi.changeFixtureTournament(this.fixture.FixtureId, this.tournamentModel.id)
                .then(() => {
                    this.tournamentMessage = this.$t('results.alerts.tournamentChanged');
                    this.tournamentMessageSeverity = 'success';
                    this.showTournamentMessage = true;
                    this.errorKeyCountTournament++;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.tournamentMessage = this.$t('results.alerts.tournamentChangeError');
                    this.tournamentMessageSeverity = 'danger';
                    this.showTournamentMessage = true;
                    this.errorKeyCountTournament++;
                });
        },
        confirmResults() {
            if (!this.fixturesActiveBool || (this.fixture.Results != null && this.fixture.Results.HomeScore !== '' && this.fixture.Results.AwayScore !== '')) {
                this.changedResults = 0;
                for (var i = 0; i < this.fixture.Markets.length; i++) {
                    if (this.fixture.Markets[i].status !== 'SETTLED') {
                        for (var j = 0; j < this.fixture.Markets[i].Outcomes.length; j++) {
                            if (this.fixture.Markets[i].Outcomes[j].Result !== this.initialFixture.Markets[i].Outcomes[j].Result) {
                                var changedOutcome = {
                                    MarketId: this.fixture.Markets[i]._id,
                                    Specifier: this.fixture.Markets[i].Specifier,
                                    OutcomeId: this.fixture.Markets[i].Outcomes[j]._id,
                                    Result: this.fixture.Markets[i].Outcomes[j].Result,
                                    IsVoided: this.fixture.Markets[i].Outcomes[j].Odds == 1 ? true : false,
                                };
                                this.changedOutcomes.push(changedOutcome);
                                this.changedResults++;
                            }
                        }
                    }
                }
                if (this.changedResults > 0) {
                    this.showModal = true;
                } else {
                    this.showToast('error', this.$t('results.alerts.noResultsChanged'));
                }
            } else {
                this.showToast('error', this.$t('results.alerts.noFinalResult'));
            }
        },
        addResults() {
            this.BoSportsApi.saveResultsDetails(this.fixtureId, this.changedOutcomes, this.fixture.Results.HomeScore, this.fixture.Results.AwayScore, this.isVoided, this.isActive, this.resultsChanged, this.fixturesActiveBool)
                .then((response) => {
                    this.closeModals();
                    this.validatedTicketsSerials = response.data;
                    this.showModalTickets = true;
                    this.resultsChanged = false;
                    this.isVoided = false;
                    this.isActive = false;
                })
                .catch((error) => {
                    this.closeModals();
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        voidEvent() {
            this.isVoided = true;
            this.showVoidModal = true;
        },
        reactivateEvent() {
            this.isActive = true;
            this.showActiveModal = true;
        },
        removeVoid() {
            this.isVoided = false;
            this.showVoidModal = false;
        },
        removeActive() {
            this.isActive = false;
            this.showActiveModal = false;
        },
        viewTickets() {
            this.goto('sportBetsTickets', {
                backRoute: this.backRoute,
                ticketSerials: this.validatedTicketsSerials,
            });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        closeModals() {
            this.showActiveModal = false;
            this.showModal = false;
            this.showModalTickets = false;
            this.showVoidModal = false;
        },
    },
};
</script>
<style scoped>
.outcomeRow {
    border-bottom: 1px solid lightgray;
}
.rightAlign {
    float: right;
}
</style>